import { getLanguage } from './translate';

const ManagePlanText = new Map<string, string>([
	['de', 'Abonnement verwalten'],
	['en', 'Manage Plan'],
	['es', 'Administrar plan'],
	['fr', 'Gérer le plan'],
	['it', 'Gestisci piano'],
	['ja', 'プランの管理'],
	['nl', 'Abonnement beheren'],
	['pt-BR', 'Gerenciar plano'],
]);

const ConvertFreeTrialText = new Map<string, string>([
	['de', 'Testversion konvertieren'],
	['en', 'Convert Free Trial'],
	['es', 'Convertir evaluación gratuita'],
	['fr', "Convertir l'évaluation gratuite"],
	['it', 'Converti versione di prova gratuita'],
	['ja', '無料トライアルに変更'],
	['nl', 'Gratis proefabonnement omzetten'],
	['pt-BR', 'Converter avaliação gratuita'],
]);

const EditBillingInfoText = new Map<string, string>([
	['de', 'Rechnungsinformationen bearbeiten'],
	['en', 'Edit Billing Info'],
	['es', 'Editar información de facturación'],
	['fr', 'Modifier les informations de facturation'],
	['it', 'Modifica informazioni di fatturazione'],
	['ja', '請求情報を編集'],
	['nl', 'Factuurgegevens bewerken'],
	['pt-BR', 'Editar informações de cobrança'],
]);

const ReceiptsText = new Map<string, string>([
	['de', 'Belege und Abrechnungsbenachrichtigungen'],
	['en', 'Receipts and Billing Notifications'],
	['es', 'Recibos y notificaciones de facturación'],
	['fr', 'Relevés & notifications de facturation'],
	['it', 'Ricevute e notifiche di fatturazione'],
	['ja', '受領書および請求書の通知'],
	['nl', 'Ontvangstbewijzen en factureringsmeldingen'],
	['pt-BR', 'Recibos e notificações de faturamento'],
]);

const CancelAccountText = new Map<string, string>([
	['de', 'Konto kündigen'],
	['en', 'Cancel Account'],
	['es', 'Cancelar cuenta'],
	['fr', 'Annuler le compte'],
	['it', 'Annulla account'],
	['ja', 'アカウントのキャンセル'],
	['nl', 'Account annuleren'],
	['pt-BR', 'Cancelar conta'],
]);

export enum RegisteredLeftNavItems {
	ManagePlan = 'ManagePlan',
	ConvertFreeTrial = 'ConvertFreeTrial',
	EditBillingInfo = 'EditBillingInfo',
	Receipts = 'Receipts',
	Cancel = 'Cancel',
}

export const getLeftNavBarText = (item: RegisteredLeftNavItems): string => {
	const language = getLanguage();
	switch (item) {
		case RegisteredLeftNavItems.ManagePlan:
			return ManagePlanText.get(language) || 'Manage Plan';
		case RegisteredLeftNavItems.ConvertFreeTrial:
			return ConvertFreeTrialText.get(language) || 'Convert Free Trial';
		case RegisteredLeftNavItems.EditBillingInfo:
			return EditBillingInfoText.get(language) || 'Edit Billing Info';
		case RegisteredLeftNavItems.Receipts:
			return ReceiptsText.get(language) || 'Receipts and Billing Notifications';
		case RegisteredLeftNavItems.Cancel:
			return CancelAccountText.get(language) || 'Cancel Account';
		default:
			return '';
	}
};
