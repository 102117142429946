import * as React from 'react';
import { Account, User } from '@citrite/sf-api';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { Spin } from 'antd';
import { LocalizationLoader } from './translate';

const Context = React.createContext<AppContext>({
	piletApi: {
		Extension: () => null,
	} as any,
	user: {},
	account: {},
	refreshUser: () => {},
});

export interface AppContext {
	piletApi: PiletApi;
	user: User;
	account: Account;
	refreshUser: () => void;
}

interface ApplicationContextProviderProps {
	piletApi: PiletApi;
	children: React.ReactNode;
}

export function ApplicationContextProvider(props: ApplicationContextProviderProps) {
	return (
		<LocalizationLoader loadingElement={<Spin />}>
			{() => (
				<Context.Provider
					value={{
						piletApi: props.piletApi,
						...props.piletApi.sf.piletUserContext.get(),
						refreshUser: props.piletApi.sf.piletUserContext.refreshUser,
					}}
				>
					{props.children}
				</Context.Provider>
			)}
		</LocalizationLoader>
	);
}

export const useApplicationContext = () => React.useContext(Context);
