import { Account, UserRole } from '@citrite/sf-api';
import { PiletUserContext } from '@sharefiledev/sharefile-appshell';

export function showManagePlan(context: PiletUserContext) {
	if (!context.user) {
		return false;
	}

	if (!context.user.Roles) {
		return false;
	}

	if (!context.user.Id) {
		return false;
	}

	if (!context.user.Roles.includes(UserRole.AdminAccountPolicies)) {
		return false;
	}

	if (!context.user.Roles.includes(UserRole.AdminChangePlan)) {
		return false;
	}

	if (!isSFHybrisAccount(context.account.BillingType)) {
		return false;
	}

	if (!showBillingInfo(context.account, context.user.Id)) {
		return false;
	}

	if (!context.account.CanChangePlan) {
		return false;
	}

	if (context.account.PlanTrack === 'Customer Account Record') {
		return false;
	}

	if (context.account.IsCancelled) {
		return false;
	}

	if (
		getDisableAdminBillingSectionPreference(context.account) === 'ShowReceipts' ||
		getDisableAdminBillingSectionPreference(context.account) === 'DisableAll'
	) {
		return false;
	}

	return true;
}

function getDisableAdminBillingSectionPreference(account: Account) {
	return account.Preferences?.DisableAdminBillingSectionInApp || '';
}

function isSFHybrisAccount(billingType?: string) {
	return billingType === 'SFHybris';
}

function showBillingInfo(account: Account, userId: string) {
	return (
		account.Preferences?.ShowBillingInfo ||
		(account.Preferences?.HideBillingInfoExceptions || '').indexOf(userId) !== -1
	);
}
